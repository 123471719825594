import React, { useEffect, useMemo, useState } from "react"
import { graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import MetaData from "../components/meta"
import RelatedResourceCard from "../components/RelatedResourceCard"
import RenderContent from "../components/RenderContent"

import "../styles/blog-post.scss"
import "../styles/glossary-post.scss"
import "highlight.js/styles/github.css"
import heroIllustrationLeft from "../images/glossary/illustration-left.png"
import heroIllustrationRight from "../images/glossary/illustration-right.png"

const GlossaryPost = ({ data, location }) => {
  const post = data.ghostPost
  const postBodyRef = React.createRef()
  const [relatedResourceSlugs, setRelatedResourceSlugs] = useState([])
  const allResources = data.allResources.edges
  const htmlAst = post.childHtmlRehype && post.childHtmlRehype.htmlAst
  const transformedHtml = post.childHtmlRehype && post.childHtmlRehype.html

  const title = useMemo(() => {
    let titleAst = htmlAst.children.find(child => {
      if (child.tagName === "h1") {
        return true
      }
    })
    let title = titleAst?.children?.[0]?.value || post.title
    let titleArray = title.split(":")

    return {
      bold: titleArray[0],
      light: titleArray.length > 1 ? titleArray[1] : null,
    }
  }, [htmlAst])

  useEffect(() => {
    const inlineRelatedResourceSlugs = postBodyRef.current.querySelectorAll(
      ".inline-related-resources span"
    )
    let slugs = []
    inlineRelatedResourceSlugs.forEach(node => {
      slugs.push(node.innerText)
    })
    setRelatedResourceSlugs(slugs)
  }, [])

  return (
    <Layout>
      <MetaData data={data} location={location} type="article" />

      {/* Add codeinjection scripts */}
      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_head }}
      ></div>

      <article className="glossary-post">
        {/* Header */}
        <header className="glossary-post-header page-header">
          <StaticImage
            src="../images/glossary/post-default-header-bg.png"
            alt=""
            layout="fullWidth"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            quality={70}
            placeholder="dominantColor"
          />
          <div className="glossary-post-header__images">
            <img
              src={heroIllustrationLeft}
              alt=""
              style={{
                position: "absolute",
                height: "100%",
                left: 0,
                top: 0,
              }}
            />
            <img
              src={heroIllustrationRight}
              alt=""
              style={{
                position: "absolute",
                height: "100%",
                right: 0,
                top: 0,
              }}
            />
          </div>
          <div className="wrapper-1200">
            <h1>
              <span>{title.bold}</span>
              {title.light && (
                <span className="light">
                  : <br />
                  {title.light}
                </span>
              )}
            </h1>
          </div>
        </header>

        {/* Content */}
        <div className="glossary-post-body wrapper-800" ref={postBodyRef}>
          <Link
            to={`/glossary/`}
            className="glossary-go-back link--with-arrow--reverse"
          >
            Back to Glossary
          </Link>

          <RenderContent
            htmlAst={htmlAst}
            html={transformedHtml || post.html}
            rehypeComponents={{
              h1: () => null,
            }}
          />
        </div>
      </article>

      {relatedResourceSlugs.length > 0 && (
        <div className="glossary-post-resources page-content-section">
          <div className="wrapper-1200">
            <div className="page-content-section__header">
              <h2>Related Resources</h2>
            </div>
            <div className="related-resources-feed">
              {relatedResourceSlugs.map(slug => (
                <RelatedResourceCard
                  post={
                    allResources.find(resource => resource.node.slug === slug)
                      ?.node
                  }
                  key={slug}
                />
              ))}
            </div>
          </div>
        </div>
      )}

      <div
        className="load-external-scripts"
        dangerouslySetInnerHTML={{ __html: post.codeinjection_foot }}
      ></div>
    </Layout>
  )
}

export default GlossaryPost

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      childHtmlRehype {
        html
        htmlAst
      }
      ...GhostPostFields
    }

    allResources: allGhostPost(
      filter: { primary_tag: { slug: { eq: "resources" } } }
    ) {
      edges {
        node {
          ...GhostPostCardFields
        }
      }
    }
  }
`
